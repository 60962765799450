<template>
  <BasicModal
    :show-action-button="false"
    :show-cancel-button="false"
  >
    <div class="stripe-checkout-return">
      <img class="plan-image" :src="stripeProduct.images ? stripeProduct.images[0] : '@/assets/images/plans/satellite-dish.png'" />
      <h4>You're on the {{ stripeProduct.name }} Plan</h4>
      <p>
        A receipt has been emailed to {{ stripeSession.customerDetails.email }}.
      </p>
      <p v-if="stripeProduct.metadata.productSlug === 'pro'">
        Congrats! You now get {{ formatMaxEventsMonthly(stripeProduct) }} Actions a month, {{ formatMaxEventsTimeframe(stripeProduct) }} of activity history, and priority support.
      </p>
      <p v-else>
        Congrats! You now get {{ formatMaxEventsMonthly(stripeProduct) }} Actions a month, and access to {{ formatMaxEventsTimeframe(stripeProduct) }} of activity history.
      </p>
      <div class="buttons">
        <button class="small" @click="close">Back to Dispatch</button>
        <a
          target="_blank"
          v-if="invoiceUrl"
          :href="invoiceUrl"
          class="button small tertiary"
        >
          See invoice
          <ExternalLinkIcon />
        </a>
      </div>
    </div>
  </BasicModal>
</template>

<script>

  import { mapState } from 'vuex'

  import useFilters from '@/composables/useFilters'

  import BasicModal from '@/components/modals/_BasicModal.vue'

  import ExternalLinkIcon from '@/assets/icons/external-link.svg'

  export default {
    components: {
      BasicModal,
      ExternalLinkIcon,
    },
    props: {
      stripeSession: {
        type: Object,
        required: true,
      },
    },
    setup() {
      const { formatMaxEventsMonthly, formatMaxEventsTimeframe } = useFilters()
      return { formatMaxEventsMonthly, formatMaxEventsTimeframe }
    },
    computed: {
      ...mapState('app', ['stripePlansIdMap', 'stripePricesIdMap']),
      stripePrice() {
        return this.stripePricesIdMap[this.stripeSession.metadata.priceId]
      },
      stripeProduct() {
        if (!this.stripePrice) return null
        return this.stripePlansIdMap[this.stripePrice.product]
      },
      invoiceUrl() {
        if (!this.invoice) return null
        return this.invoice.hostedInvoiceUrl
      },
    },
    data() {
      return {
        invoice: null,
      }
    },
    created() {

      if (!this.stripeProduct) {
        this.close()
        return
      }

      this.$store.state.api.dispatch.get(`/stripe/invoices/${this.stripeSession.invoice}`)
        .then(((response) => {
          this.invoice = response.data
        }))

    },
    methods: {
      close() {
        this.$store.dispatch('modals/CLOSE_MODAL')
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .stripe-checkout-return
    @apply flex
    @apply flex-col
    @apply text-center
    @apply items-center

    h4
      @apply my-4

    p + p
      @apply mt-4

    .plan-image
      @apply w-32
      @apply h-32

    .buttons
      @apply mt-6
      @apply flex
      @apply gap-4

      svg
        @apply w-4
        @apply h-4
        @apply ml-2

</style>
